
import Vue, { PropType } from 'vue'
import { IAlert } from '@/models/Store.model'

export default Vue.extend({
  name: 'AlertComponent',
  props: {
    alert: {
      type: Object as PropType<IAlert>,
      required: true
    }
  },
  data (): any {
    return {
      timout: null
    }
  },
  computed: {
    innerValue (): boolean | undefined {
      return this.alert.value
    },
    imageAlert (): string {
      if (this.alert.type === 'success') {
        return '/images/success.svg'
      }
      return '/images/alert-error.svg'
    }
  },
  watch: {
    innerValue (val: boolean): void {
      if (val && !this.alert.isConfirm) {
        this.setTimeout()
      }
    }
  },
  methods: {
    setTimeout ():void {
      this.timout = setTimeout((): void => {
        this.close()
      }, 1000)
    },
    close (): void {
      this.$store.dispatch('Notification/closeAlert')
      clearTimeout(this.timout)
    },
    onConfirm (): void {
      this.close()
    }
  }
})
