import Vue from 'vue'
import VueRouter, {
  NavigationGuardNext,
  Route,
  RouteConfig } from 'vue-router'

import AuthRoute from './Modules/auth/index.route'
import DashboardRoute from './Modules/Dashboard.route'
import SettingRoute from './Modules/settings/index.route'
import CampaignRoute from './Modules/campaign/index.route'
import InfluencerRoute from './Modules/Influencer/index.route'
import { getUserAccessToken } from '@/utils/Authentication'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'HomePage',
    redirect: { name: 'CampaignListPage' }
  },
  CampaignRoute,
  InfluencerRoute,
  AuthRoute,
  DashboardRoute,
  SettingRoute
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (): any {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): any => {
  // console.log(to, from, next)
  if (to.meta?.auth) {
    const userToken = getUserAccessToken()
    if (userToken) {
      return next()
    }
    return router.replace({ name: 'LoginPage' })
  }
  return next()
})

const DEFAULT_TITLE: string = 'mi_kol'

router.afterEach((to: Route): void => {
  Vue.nextTick((): void => {
    document.title = to?.meta?.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE
  })
})

export default router
