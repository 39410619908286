<template>
  <v-footer
    color="white"
    class=" pa-0">
    <v-container>
      <v-row>
        <v-col
          cols="auto"
          class=""
          style="align-self: center;">
          <img
            class="mr-2"
            src="/images/logo.svg" />
          <!-- <img src="/images/Frame.png" /> -->
        </v-col>
        <v-col
          cols="auto"
          class="pa-5">
          <!-- <div
            style="font-weight: 600;"
            class="mb-2">
            CONTACT
          </div> -->
          <div style="font-weight: 600;">
            Media Intelligence Group CO.,LTD.
          </div>
          <div class="font-weight-light mb-1">
            140 Wireless Building, 15h Floor  Wireless Road, Lumpini, Pathumwan, Bangkok 10330
          </div>
          <div class="d-flex align-center">
            <img
              class="mr-2"
              src="/images/facebook-icon.svg" />
            <!-- <img
              class="mr-2"
              src="/images/phone-icon.svg" />
            <img
              class="mr-2"
              src="/images/line-icon.svg" /> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div style="width: 100%; background:linear-gradient(111deg, #F05F25 0%, #B73E76 100%);">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <div class="body-2 white--text">
            Copyright © 2024 Company All rights reserved.
          </div>
          <div class="d-flex body-2 white--text">
            <div class="text-decoration-underline">
              PRIVACY POLICY
            </div>
            <div class="mx-1">
              |
            </div>
            <div class="text-decoration-underline">
              TERMS OF USE YOUR
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </v-footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
*{
  font-family: "Inter";
}
</style>
