
import Vue from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'
import menus from '@/assets/ts/menu'

interface IData {
  drawer: boolean
  mini: boolean
  menus: any
}

export default Vue.extend({
  name: 'NavigationDrawer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data (): IData {
    return {
      drawer: false,
      mini: false,
      menus
    }
  },
  computed: {
    userAccount (): any {
      return this.$store.getters['User/user']
    },
    listHeight (): string {
      if (this.isMobile) {
        return 'height: calc(100vh - 58px);'
      }
      return 'height: calc(100vh - 212px);'
    }
  },
  mounted (): void {
    const container = document.querySelector('.drawer__list') as Element
    const ps = new PerfectScrollbar(container, {
      suppressScrollX: true
    })
    ps.update()
  },
  methods: {
    logout (): void {
      this.$store.dispatch('User/logout')
      this.$router.replace({ name: 'LoginPage' })
    },
    toggleIsMini (): void {
      // this.mini = !this.mini
      this.drawer = false
    },
    closeSideBar (value: boolean): void {
      if (!value) {
        this.$emit('click:close')
      }
    }
  }
})
