import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/th'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(customParseFormat)
dayjs.extend(buddhistEra)
dayjs.extend(localeData)
dayjs.extend(isBetween)

dayjs.locale('th')

Vue.prototype.$dayjs = dayjs

export default dayjs
