export default {
  path: '',
  component: (): any => import('@/views/Settings/setting-router.vue'),
  children: [
    {
      path: '',
      name: 'SettingListPage',
      component: (): any => import('@/views/Settings/page/setting.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'profile-setting',
      name: 'ProfileSetting',
      component: (): any => import('@/views/Settings/page/ProfileSetting.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'change-password',
      name: 'ChangePassword',
      component: (): any => import('@/views/Settings/page/ChangePassword.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'user-management',
      name: 'UserManage',
      component: (): any => import('@/views/Settings/page/UserManage.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'user-create',
      name: 'UserCreate',
      component: (): any => import('@/views/Settings/page/UserCreate.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'user-detail/:id',
      name: 'UserDetail',
      component: (): any => import('@/views/Settings/page/UserDetail.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'user-edit/:id',
      name: 'UserEdit',
      component: (): any => import('@/views/Settings/page/UserEdit.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'attribute-setting',
      name: 'AttributeSetting',
      component: (): any => import('@/views/Settings/page/AttributeSetting.vue'),
      meta: {
        auth: true
      }
    },
    {
      path: 'attribute-setting-Brand',
      name: 'AttributeSettingBrand',
      component: (): any => import('@/views/Settings/page/AttributeSettingBrand.vue'),
      meta: {
        auth: true
      }
    }

    // {
    //   path: 'detail/:id',
    //   name: 'DepartmentDetailPage',
    //   component: (): any => import('@/views/setting/department/pages/DepartmentDetailPage.vue'),
    //   meta: {
    //     auth: true,
    //     title: 'รายละเอียดแผนก',
    //     icon: ''
    //   }
    // }
  ]
}
