
import Vue from 'vue'

export default Vue.extend({
  name: 'LoadingComponent',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['Loading/isLoading']
    }
  }
})
