import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ContainerVue from './components/Container.vue'

import vuetify from '@/plugins/Vuetify.plugin'

import 'perfect-scrollbar/css/perfect-scrollbar.css'
import '@/assets/scss/style.scss'

import '@/plugins/Dayjs.plugin'
import '@/plugins/Keypress.plugin'
import '@/plugins/Filter.plugin'
import '@/plugins/Validate'

Vue.config.productionTip = false
Vue.component('ContainerSection', ContainerVue)

new Vue({
  router,
  store,
  vuetify,
  render: (h: any): any => h(App)
}).$mount('#app')
