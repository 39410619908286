import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ILoadingState, IRootState } from '@/models/Store.model'

type TAction = ActionTree<ILoadingState, IRootState>
type TActionContext = ActionContext<ILoadingState, IRootState>
type TMutation = MutationTree<ILoadingState>
type TGetter = GetterTree<ILoadingState, IRootState>

const state: ILoadingState = {
  loadingCount: 0
}

const actions: TAction = {
  addLoading ({ commit, getters }: TActionContext): void {
    commit('SET_LOADING_COUNT', getters.loadingCount + 1)
  },
  removeLoading ({ commit, getters }: TActionContext): void {
    let count: number = getters.loadingCount - 1
    if (count < 0) {
      count = 0
    }
    commit('SET_LOADING_COUNT', count)
  },
  clearLoading ({ commit }: TActionContext): void {
    commit('SET_LOADING_COUNT', 0)
  }
}

const mutations: TMutation = {
  SET_LOADING_COUNT (state: ILoadingState, payload: number): void {
    state.loadingCount = payload
  }
}

const getters: TGetter = {
  isLoading: (state: ILoadingState): boolean => state.loadingCount > 0,
  loadingCount: (state: ILoadingState): number => state.loadingCount
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
