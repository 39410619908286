export default {
  path: '/dashboard',
  name: 'DashboardPage',
  component: (): any => import('@/views/Dashboard.vue'),
  meta: {
    auth: true,
    title: 'แดชบอร์ด',
    icon: ''
  }
}
