export default {
  path: '',
  component: (): any => import('@/views/Campaign/CampaignPage.vue'),
  children: [
    {
      path: '',
      name: 'CampaignListPage',
      component: (): any => import('@/views/Campaign/pages/CampaignList.vue'),
      meta: {
        auth: true,
        // title: 'Campaign',
        icon: '/icons/setting.svg',
        query: 'campaign'
      }
    },
    {
      path: 'create-campaign',
      name: 'CreateCampaign',
      component: (): any => import('@/views/Campaign/pages/CampaignCreate.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'campaign-detail/:id',
      name: 'CampaignDetail',
      component: (): any => import('@/views/Campaign/pages/CampaignDetail.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'campaign-edit/:id',
      name: 'CampaignEdit',
      component: (): any => import('@/views/Campaign/pages/CampaignEdit.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'overview-graph/:id',
      name: 'OverviewGraph',
      component: (): any => import('@/views/Campaign/pages/OverviewGraph.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'overview-graph-export/:id',
      name: 'OverviewGraphExport',
      component: (): any => import('@/views/Campaign/pages/OverviewGraphExport.vue'),
      meta: {
        title: 'export',
        auth: true,
        icon: '',
        layout: 'blank'
      }
    }
    // {
    //   path: 'edit/:id',
    //   name: 'DepartmentEditPage',
    //   component: (): any => import('@/views/setting/department/pages/DepartmentEditPage.vue'),
    //   meta: {
    //     auth: true,
    //     title: 'แก้ไขแผนก',
    //     icon: ''
    //   }
    // },
    // {
    //   path: 'detail/:id',
    //   name: 'DepartmentDetailPage',
    //   component: (): any => import('@/views/setting/department/pages/DepartmentDetailPage.vue'),
    //   meta: {
    //     auth: true,
    //     title: 'รายละเอียดแผนก',
    //     icon: ''
    //   }
    // }
  ]
}
