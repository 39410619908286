export default {
  path: '/auth',
  component: (): any => import('@/views/auth/Auth.vue'),
  children: [
    {
      path: 'login',
      name: 'LoginPage',
      component: (): any => import('@/views/auth/pages/LoginPage.vue'),
      meta: {
        title: 'เข้าสู่ระบบ',
        icon: '',
        layout: 'blank'
      }
    },
    {
      path: 'logout',
      name: 'LogoutPage',
      component: (): any => import('@/views/auth/pages/LogoutPage.vue'),
      meta: {
        title: 'ออกจากระบบ',
        icon: '',
        layout: 'blank'
      }
    }
  ]
}
