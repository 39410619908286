import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { IUserState, IRootState } from '@/models/Store.model'
import AuthProvider, { IAuthProvider } from '@/resources/Auth.provider'
import { ILoginForm } from '@/models/Auth.model'

type TAction = ActionTree<IUserState, IRootState>
type TActionContext = ActionContext<IUserState, IRootState>
type TMutation = MutationTree<IUserState>
type TGetter = GetterTree<IUserState, IRootState>

const authService: IAuthProvider = new AuthProvider()

const state: IUserState = {
  id: null,
  imageUrl: '',
  role: '',
  email: '',
  firstName: '',
  lastName: '',
  tokenExpire: 0,
  accessToken: ''
}

const actions: TAction = {
  async login ({ commit }: TActionContext, payload: ILoginForm): Promise<void> {
    const response = await authService.loginUser({
      email: payload.email,
      password: payload.password
    })

    if (response?.accessToken) {
      commit('SET_USER', {
        id: response.data.id || null,
        imageUrl: response.data.imageUrl,
        role: response.data.role,
        email: response.data.email || '',
        firstName: response.data.firstName || '',
        lastName: response.data.lastName || '',
        tokenExpire: response.data?.tokenExpire || 0,
        accessToken: response.accessToken || ''
      })
    } else {
      const error: any = {
        statusCode: 400,
        message: 'Access token not found.'
      }
      throw new Error(error)
    }
  },
  logout ({ commit }: TActionContext): void {
    commit('CLEAR_USER')
  }
}

const mutations: TMutation = {
  SET_USER (state: IUserState, payload: IUserState): void {
    state.id = payload.id || state.id || null
    state.email = payload.email || state.email || ''
    state.imageUrl = payload.imageUrl || state.imageUrl || ''
    state.role = payload.role || state.role || ''
    state.firstName = payload.firstName || ''
    state.lastName = payload.lastName || ''
    state.tokenExpire = payload.tokenExpire || state.tokenExpire || 0
    state.accessToken = payload.accessToken || state.accessToken || ''
  },
  CLEAR_USER (state: IUserState): void {
    state.id = null
    state.email = ''
    state.imageUrl = ''
    state.role = ''
    state.firstName = ''
    state.lastName = ''
    state.tokenExpire = 0
    state.accessToken = ''
  }
}

const getters: TGetter = {
  user: (state: IUserState): IUserState => ({ ...state }),
  userId: (state: IUserState): number => state.id as number,
  accessToken: (state: IUserState): string => state.accessToken,
  tokenExpire: (state: IUserState): number => state.tokenExpire
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
