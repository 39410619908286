export default {
  path: '',
  component: (): any => import('@/views/Influencer/InfluencerPage.vue'),
  children: [
    {
      path: '',
      name: 'InfluencerListPage',
      component: (): any => import('@/views/Influencer/pages/InfluencerList.vue'),
      meta: {
        auth: true,
        icon: '/icons/setting.svg',
        query: 'influencer'
      }
    },
    {
      path: 'influencer-create',
      name: 'InfluencerCreate',
      component: (): any => import('@/views/Influencer/pages/InfluencerCreate.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'influencer-detail/:id',
      name: 'InfluencerDetail',
      component: (): any => import('@/views/Influencer/pages/InfluencerDetail.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    },
    {
      path: 'influencer-edit/:id',
      name: 'InfluencerEdit',
      component: (): any => import('@/views/Influencer/pages/InfluencerEdit.vue'),
      meta: {
        auth: true,
        icon: ''
      }
    }
  ]
}
