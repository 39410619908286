
import Vue from 'vue'
import Default from '@/layouts/Default.vue'
import Blank from '@/layouts/Blank.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Default,
    Blank
  },
  computed: {
    layoutName (): string {
      const layoutName: string = this.$route?.meta?.layout?.toLowerCase() || 'default'
      return layoutName
    }
  }
})
