import store from '@/store'

interface IToken {
  key: string
  value: string
}

export const getUserAccessToken = (): string => {
  const accessToken: string = store.getters['User/accessToken']
  const tokenExpire: number = store.getters['User/tokenExpire']
  const dateTime: number = new Date().getTime()

  if (!accessToken || !tokenExpire || tokenExpire <= dateTime) {
    store.dispatch('User/logout')
    return ''
  }
  return accessToken
}

export const getUserAuthToken = (): IToken | null => {
  const token: IToken = {
    key: 'Authorization',
    value: 'Bearer'
  }
  const accessToken: string = getUserAccessToken()
  if (accessToken) {
    token.value += ` ${accessToken}`
    return token
  }
  return null
}

export default {
  getUserAccessToken,
  getUserAuthToken
}
