import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { IRootState } from '@/models/Store.model'
import { vuexUser } from '@/plugins/VuexPersist.plugin'

import LoadingModule from './loading'
import UserModule from './user'
import NotificationModule from './notification'

Vue.use(Vuex)

const store: StoreOptions<IRootState> = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Loading: LoadingModule,
    User: UserModule,
    Notification: NotificationModule
  },
  plugins: [
    vuexUser.plugin
  ]
}

export default new Vuex.Store(store)
