import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 800
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        'main': '#DA2621',
        'primary': '#2C343f',
        'secondary': '#05928D',
        'error': '#DD2025',
        'info': '#757575',
        'success': '#00A568',
        'disabled': '#3A3541',
        'black': '#1D1135',
        'light-gray': '#DDDDDD',
        'gray': '#AAAAAA',
        'yellow': '#FAB811'
      }
    }
  }
})
