
import Vue from 'vue'
import Loading from '@/components/Loading.vue'

export default Vue.extend({
  name: 'LayoutBlank',
  components: {
    Loading
  }
})
