import HttpRequest from './HttpRequest'
import { ILoginForm } from '@/models/Auth.model'
import {
  IUserLoginResponse
} from '@/models/response/UserResponse.model'

export interface IAuthProvider {
  loginUser(payload: ILoginForm): Promise<IUserLoginResponse>
}

class AuthProvider extends HttpRequest implements IAuthProvider {
  private urlUserLogin: string = '/v1/public/users'

  public async loginUser (payload: ILoginForm): Promise<IUserLoginResponse> {
    return this.post(this.urlUserLogin, payload)
    // return {
    //   data: response.data,
    //   token: response.data.token,
    //   expired: response.data.expireIn * 1000
    // }
  }
}

export default AuthProvider
