import { IRawSystemPermission, ISystemPermission } from '@/models/setting/SystemPermission.model'
import permissionTranslate from './json/permissions/PermissionTranslate.json'
import purchase from './json/permissions/Purchase.json'
import purchaseTranslate from './json/permissions/PurchaseTranslate.json'
import warehouse from './json/permissions/Warehouse.json'
import warehouseTranslate from './json/permissions/WarehouseTranslate.json'

export const systemPermissionList: ISystemPermission[] = [
  {
    domain: 'dashboard',
    disabled: true,
    pages: []
  },
  {
    domain: 'sales',
    disabled: true,
    pages: []
  },
  {
    domain: 'account',
    disabled: true,
    pages: []
  },
  {
    domain: 'finance',
    disabled: true,
    pages: []
  },
  purchase,
  warehouse,
  {
    domain: 'production',
    disabled: true,
    pages: []
  },
  {
    domain: 'partner',
    disabled: true,
    pages: []
  },
  {
    domain: 'setting',
    disabled: true,
    pages: []
  },
  {
    domain: 'manageAccount',
    disabled: true,
    pages: []
  }
]

export const systemPermissionTranslate: any = {
  ...permissionTranslate,
  ...warehouseTranslate,
  ...purchaseTranslate,
  dashboard: 'ภาพรวม',
  sales: 'ขาย',
  account: 'บัญชี',
  finance: 'การเงิน',
  production: 'ผลิต',
  partner: 'ทะเบียนคู่ค้า',
  setting: 'การตั้งค่า',
  manageAccount: 'บริหารบัญชี'
}

// export function mapSystemPermission (items: IRawSystemPermission[]): ISystemPermission[] {
//   return systemPermissionList.map((item: ISystemPermission): ISystemPermission => {
//     const newItem = item
//     const domainItems = items.filter((r: IRawSystemPermission): boolean => r.domain === item.domain)
//     if (domainItems.length) {
//       newItem.pages.map((page: any): any => {
//         const newPage = page
//         const pageItem = domainItems.find((r: IRawSystemPermission): boolean => r.subDomain === page.subDomain)
//         if (pageItem) {
//           newPage.permission.map((permission: any): any => {
//             const newPermission = permission
//             const permissionItem = pageItem.permission.find((r: any): boolean => r.feature === permission.feature)
//             if (permissionItem?.feature) {
//               newPermission.allow = permissionItem.allow || false
//             }
//             return newPermission
//           })
//         }
//         return newPage
//       })
//     }
//     return newItem
//   })
// }

function rawToForm (items: IRawSystemPermission[]): ISystemPermission[] {
  return systemPermissionList.map((item: ISystemPermission): ISystemPermission => {
    const domainItems = items.filter((r: IRawSystemPermission): boolean => r.domain === item.domain)
    const updatedPages = item.pages.map((page: any): any => {
      const pageItem = domainItems.find((r: any): any => r.subDomain === page.subDomain)
      if (!pageItem) return page
      const updatedPermissions = page.permission.map((permission: any): any => {
        const permissionItem = pageItem.permission.find((r: any): any => r.feature === permission.feature)
        if (!permissionItem) return permission
        return {
          ...permission,
          allow: permissionItem.allow || false
        }
      })
      return {
        ...page,
        id: pageItem.id,
        permission: updatedPermissions
      }
    })
    return {
      ...item,
      pages: updatedPages
    }
  })
}

function formToRaw (items: ISystemPermission[]): IRawSystemPermission[] {
  const data: IRawSystemPermission[] = []
  items.forEach((item: ISystemPermission): void => {
    item.pages.forEach((page: any): void => {
      if (page.id) {
        data.push({
          id: page.id,
          domain: item.domain,
          subDomain: page.subDomain,
          permission: page.permission
        })
      }
    })
  })
  return data
}

interface IMapSystemPermission {
  rawToForm (items: IRawSystemPermission[]): ISystemPermission[]
  formToRaw (items: ISystemPermission[]): IRawSystemPermission[]
}

export const mapSystemPermission: IMapSystemPermission = {
  rawToForm,
  formToRaw
}
