import VuexPersistence from 'vuex-persist'
import { IRootState } from '@/models/Store.model'

export const vuexUser: VuexPersistence<IRootState> = new VuexPersistence({
  key: 'mi_kol',
  saveState: (key: string, state: IRootState): void => {
    const stateUser: any = state.User
    const jsonString = btoa(window.unescape(encodeURIComponent(JSON.stringify(stateUser))))
    window.localStorage.setItem(key, jsonString)
  },
  restoreState: (key: string): IRootState => {
    let userJsonString: any = window.localStorage.getItem(key) || ''
    if (userJsonString && typeof userJsonString === 'string') {
      userJsonString = JSON.parse(decodeURIComponent(window.escape(atob(userJsonString))))
    }
    return {
      User: {
        ...userJsonString
      }
    }
  },
  modules: ['User']
})

export default {
  vuexUser
}
