
import Vue from 'vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
// import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import { IAlert } from '@/models/Store.model'
import AppFooter from '@/components/AppFooter.vue'

interface IData {
  isOpen: boolean
  ready: boolean
}

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    // AppBar,
    NavigationDrawer,
    Loading,
    Alert,
    AppFooter
  },
  data (): IData {
    return {
      isOpen: false,
      ready: false
    }
  },
  computed: {
    isAuth (): boolean {
      return !!this.$route.meta?.userAuth || !!this.$route.meta?.merchantAuth
    },
    isMobile (): boolean {
      return this.$vuetify.breakpoint.mobile
    },
    alert (): IAlert {
      return this.$store.getters['Notification/alert']
    }
  },
  mounted (): void {
    this.ready = true
  },
  methods: {
    toggleSideBar (value: boolean): void {
      this.isOpen = !!value
    }
  }
})
