import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { INotificationState, IAlert, IRootState } from '@/models/Store.model'

type TAction = ActionTree<INotificationState, IRootState>
type TActionContext = ActionContext<INotificationState, IRootState>
type TMutation = MutationTree<INotificationState>
type TGetter = GetterTree<INotificationState, IRootState>

const state: INotificationState = {
  alert: {
    value: false,
    type: '',
    title: '',
    subtitle: '',
    confirmText: '',
    isConfirm: false
  }
}

const actions: TAction = {
  showAlert ({ commit, getters }: TActionContext, payload: IAlert): void {
    commit('SHOW_ALERT', {
      ...getters.alert,
      ...payload,
      value: true
    })
  },
  closeAlert ({ commit }: TActionContext): void {
    commit('CLOSE_ALERT', {
      value: false,
      type: '',
      title: '',
      subtitle: '',
      confirmText: '',
      isConfirm: false
    })
  }
}

const mutations: TMutation = {
  SHOW_ALERT (state: INotificationState, payload: IAlert): void {
    state.alert = { ...payload }
  },
  CLOSE_ALERT (state: INotificationState, payload: IAlert): void {
    state.alert.value = payload.value
    setTimeout((): void => {
      state.alert = { ...payload }
    }, 300)
  }
}

const getters: TGetter = {
  alert: (state: INotificationState): IAlert => state.alert
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
