import Vue from 'vue'

const emailRegex: RegExp = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/

export interface IValidate {
  required (val: string): any
  email (val: string): any
  phoneNumber (val: string): any
  confirmPassword (val: string, password: string): any
  length (val: string, length: number): any
  minLength (val: string, length: number): any
  maxLength (val: string, length: number): any,
  between (val: string, min: number, max: number): any
}

export const validate: IValidate = {
  required: (val: string): any => !!val || 'จำเป็นต้องกรอก',
  email: (val: string): any => emailRegex.test(val) || 'อีเมลไม่ถูกต้อง',
  phoneNumber: (val: string): any => val.length >= 9 && val.length <= 10,
  confirmPassword: (val: string, password: string): any => val === password || 'รหัสผ่านไม่ตรงกัน',
  length: (val: string, length: number): any => (!!val && val.length === length) || `ต้องมีความยาว ${length} ตัวอักษร`,
  minLength: (val: string, length: number): any => (!!val && val.length >= length) || `ต้องกรอกอย่างน้อย ${length} ตัวอักษร`,
  maxLength: (val: string, length: number): any => (!!val && val.length <= length) || `ต้องกรอกไม่เกิน ${length} ตัวอักษร`,
  between: (val: string, min: number, max: number): any => (!!val && val.length >= min && val.length <= max)
  || `ต้องเป็นค่าระหว่าง ${min} และ ${max} ตัวอักษร`
}

Vue.prototype.$validate = validate
