import Vue from 'vue'

export interface IKeypress {
  number (evt: any): boolean
  numberNoDecimal (evt: any): boolean
  telInput (evt: any): boolean
}

export const keypress = {
  number: (evt: any): boolean => {
    const event = (evt) || window.event
    const charCode = (event.which) ? event.which : event.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      event.preventDefault()
      return false
    }
    return true
  },
  numberNoDecimal: (evt: any): boolean => {
    const event = (evt) || window.event
    const charCode = (event.which) ? event.which : event.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
      event.preventDefault()
      return false
    }
    return true
  },
  telInput: (evt: any): boolean => {
    const event = (evt) || window.event
    const charCode = (event.which) ? event.which : event.keyCode
    if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) && charCode !== 43) {
      event.preventDefault()
      return false
    }
    return true
  }
}

Vue.prototype.$keypress = keypress

export default keypress
