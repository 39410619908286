// import settingMenu from './modules/setting.menu'

export default [
  {
    title: 'Campaign',
    to: 'CampaignListPage',
    value: '',
    icon: '/icons/dashboard.svg',
    subMenu: []
  },
  {
    title: 'Influencer',
    to: 'InfluencerListPage',
    value: '',
    icon: '/icons/dashboard.svg',
    subMenu: []
  },
  {
    title: 'Setting',
    to: 'SettingListPage',
    value: '',
    icon: '/icons/dashboard.svg',
    subMenu: []
  }
  // settingMenu,
  // {
  //   title: 'บริหารบัญชี',
  //   to: '',
  //   value: '',
  //   icon: '/icons/clipboard.svg',
  //   subMenu: [
  //     {
  //       title: '',
  //       icon: '',
  //       to: ''
  //     }
  //   ]
  // }
]
