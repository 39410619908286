import Vue from 'vue'
import { systemPermissionTranslate } from '@/utils/Permissions'
import { ApprovalTranslate } from '@/utils/Approval'

export interface IFilters {
  numberFormat (_number: string | number): string
  numberFormat2Decimal (_number: string | number): string,
  warehouseType (val: boolean): string,
  approvalStatus (val: string | number): string
  systemPermissionTranslator (val: string): string
  ApprovalTransalte (val: string): string
}

function numberParseFloat (_number: string | number): number {
  if (typeof _number === 'string') {
    return parseFloat(_number)
  }
  return _number
}

export function numberFormat (_number: string | number = 0): string {
  const num: number = numberParseFloat(_number)
  if (num === 0 || num) {
    return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
}

export function numberFormat2Decimal (_number: string | number = 0): string {
  const num: number = numberParseFloat(_number)
  if (num === 0 || num) {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
}

export function warehouseType (val: boolean): string {
  return val ? 'รวม VAT' : 'ไม่รวม VAT'
}

export function approvalStatus (val: string | number): string {
  switch (val) {
    case 'create':
      return 'สร้าง'
    case 'approved':
      return 'อนุมัติ'
    case 'pending':
      return 'รออนุมัติ'
    case 'rejected':
      return 'ยกเลิก'
    default: return 'ไม่อนุมัติ'
  }
}

export function systemPermissionTranslator (val: string): string {
  return systemPermissionTranslate[val] || val
}

export function ApprovalTransalte (val: string): string {
  return ApprovalTranslate[val] || val
}

const filters: IFilters = {
  numberFormat,
  numberFormat2Decimal,
  warehouseType,
  approvalStatus,
  systemPermissionTranslator,
  ApprovalTransalte
}

Vue.prototype.$filters = filters

export default filters
